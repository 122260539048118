import React from "react"
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import HeroImage from '../components/HeroImage'
import Map from '../components/Map'
import Hours from '../components/Hours'
import SERVICES from '../data/services'
import SEO from '../components/SEO'

const App = props => {
  const hero_text = "Comprehensive Massage Therapy";
  const sub_text = "mBody Health has experience working with a broad spectrum of clientele – from Athletes seeking to recover, to Seniors treating chronic pain."
  return(
    <Layout>
      <SEO
        title="Home"
      />
      <HeroImage heroText={hero_text} subtext={sub_text} cta="Request an Appointment" class="hero-image" source="/static/deep-tissue-a56a5216831010f82d6969e3351aaf5d.jpg"/>
      <div id="services" className="padding-content-section">
        <h3 className="responsive-title">Our Practice</h3>
        <p>
          At mBody Health, all clients are treated respectfully.
          Our style of service allows you the autonomy to be creative and active in your body’s health and maintenance.
          As you work with our trained professional staff, understand that we will serve you with the utmost honesty;
          only offering advice and service necessary to promote your body’s health.
        </p>
        <p>
          mBody Health treats disorders of the body by manipulating the soft tissue through external application.
          No harm or maltreatment is intended. The goal of treatment is for clients to exit  treatment feeling better
          and thinking healthier.
        </p>
        <p>
          mBody Health does not discriminate in its treatment to qualified clients.
          Individuals are attended without regard to race, color, creed, nationality, religion, sex, or age
          in all matters.
        </p>
        <Link to="/our-practice" aria-label="learn more about our practice"><button className="button button-primary">Learn more about our practice</button></Link>
      </div>
      <div id="about" className="tertiary padding-content-section">
        <h3 className="responsive-title">Our Services</h3>
        <p>mBody Health is a massage therapy clinic based in Akron, Ohio, offering a wide variety of therapeutic massages, including:</p>
        <div>
          <div className='services-list-abbr'>
          {
            SERVICES.map(service => (
              <div className="abbr-list-item" key={service.service_id}>{service.service_type}</div>
            ))
          }
          </div>
        <Link to="/contact" aria-label="Request an Appointment"><button className="button button-primary">Request an Appointment</button></Link>
        <Link to="/services" aria-label="See our full list of services"><button className="button button-secondary">Need more info? See our full list of services</button></Link>
      </div>

      </div>
      <HeroImage heroText="Hours" subtext={<Hours />} cta="" class="hero-image-alt" source="/static/massage_room-042a327c29954e9d75358193154f09ef.jpg"/>
      <div id="location">
        <Map />
      </div>
    </Layout>
  )
}

export default App;
